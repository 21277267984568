<template>
  <div>
    <div class="w-1/12 mx-5 flex flex-wrap">
      <vs-radio
        class="w-full my-1"
        v-model="radios1"
        vs-name="radios1"
        vs-value="all"
        >all</vs-radio
      >
      <vs-radio
        class="w-full my-1"
        v-model="radios1"
        vs-name="radios1"
        vs-value="withdraw"
        >withdraw</vs-radio
      >
      <vs-radio
        class="w-full my-1"
        v-model="radios1"
        vs-name="radios1"
        vs-value="deposit"
        >deposit</vs-radio
      >
    </div>
    <vs-table search v-model="selectedUser" :data="userList">
      <template slot="header">
        <div class="mb-base"></div>
      </template>
      <template slot="thead">
        <vs-th> ID </vs-th>
        <vs-th> کاربر </vs-th>
        <vs-th> دلیل تراکنش </vs-th>
        <vs-th> نوع </vs-th>
        <vs-th> مقدار (تومان) </vs-th>
        <vs-th> تاریخ </vs-th>
        <vs-th> وضعیت </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].id"> {{ tr.user_id }} </vs-td>
          <vs-td :data="data[indextr].user_fullname">
            {{ tr.user_fullname }}
          </vs-td>
          <vs-td :data="data[indextr].subject">
            {{ data[indextr].meta }}
          </vs-td>
          <vs-td :data="data[indextr].category_name">
            {{ data[indextr].type }}
          </vs-td>
          <vs-td
            :class="data[indextr].amount >= 0 ? 'text-success' : 'text-danger'"
            :data="data[indextr].category_name"
          >
            {{ data[indextr].amount }}
          </vs-td>
          <vs-td :data="data[indextr].updated_at">
            {{ data[indextr].created_at }}</vs-td
          >
          <vs-td :data="data[indextr].updated_at">
            {{ data[indextr].confirmed ? "تایید شده" : "تایید نشده" }}</vs-td
          >
        </vs-tr>
      </template>
    </vs-table>

    <vs-pagination :total="countPage" v-model="pageNumber"></vs-pagination>
  </div>
</template>

<script>
export default {
  data: () => ({
    radios1: "all",
    countPage: 0,
    pageNumber: 1,
    selectedUser: [],
    userList: [],
    userId: "",
  }),
  watch: {
    pageNumber() {
      this.getAllTrans();
    },
    radios1() {
      this.getAllTrans();
    },
  },
  methods: {
    getAllTrans() {
      this.$vs.loading();
      this.$http
        .get(
          `/admin/billing/pay/transaction?type=${this.radios1}&user_id=${this.userId}&page=${this.pageNumber}`
        )
        .then((res) => {
          this.$vs.loading.close();
          const p = res.data.params;
          this.countPage = p.last_page;
          this.userList = p.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
  },
  created() {
    this.userId = this.$route.params.id;
    this.getAllTrans();
  },
};
</script>